import React, { useEffect, useState } from 'react'

import update from 'react-addons-update'
import { useNavigate } from 'react-router'
import { useToasts } from 'react-toast-notifications'
import styled, { useTheme, CSS } from 'styled-components'

import { APP_DEFAULT_STATE } from '@api/local'
import { CartPlugin } from '@api/local/CartPlugin'
import { ModalsPlugin, GlobalModalTypeEnum } from '@api/local/ModalsPlugin'
import { ResponsiveImage, Button, Spacer, Heading, Paragraph, Pill, IconEnum } from '@atoms/index'
import { Icon, LowStockContainer, MealkitModal, ReactivateSubscriptionModal, Rule, UpSellProductsModal, useOutOfStockNotifier } from '@client/components'
import { useConfig } from '@client/contexts/ConfigProvider'
import { SiteHelper } from '@client/lib/SiteHelper'
import { MiniProductCardImageContainer, MiniProductCardContainer, MiniProductCardContentContainer, ResponsivePXValue, Ellipsis, ServingSizeEnum } from '@components/Theme'
import { useEvents } from '@contexts/GTMProvider'
import { useUserDetailsQuery, useUserCartQuery, UserMenuFragment, UserCartDocument, useAddDishToUserMenuMutation, useAddItemToCartMutation, useChangeCartItemQuantityMutation, useRemoveDishFromUserMenuMutation, useRemoveItemFromCartMutation, FrozenMealDishListFragment, MealKitDishListFragment, WineListFragment, MarketProductListFragment, UserCheckoutStatusFragment, FrozenMealListFragment, UserSubscriptionFragment, UserDetailsFragment, RegisteredUserDetailsFragment, UpSellFrozenMealDishFragment, UpSellMarketProductFragment, UpSellWineFragment, useGetAppQuery } from '@hooks/api'
import { ItemInterfaceNew } from '@lib/GTM'
import { Utilities } from '@lib/Utilities'
import { _handleWeekendBoxDeliveryText, DiscoveryVitality, FieldData, Form, getCategoryBackground, getUpSellProducts, RadioButton, RadioButtonProps, useForm, SelectOption, SelectInput, convertEnumToNum, HashAnchor } from '@molecules/index'
import { FrozenPortionSizeEnum, ProductAvailabilityEnum, PortionSizeEnum, ProductStockStatusEnum, NumberOfPortionsEnum, Product, ProductOrderTypeEnum } from '@uctypes/api/globalTypes'

import { FloatingUpSell } from './FloatingUpSell'

type CombinedProduct = FrozenMealDishListFragment | MealKitDishListFragment | WineListFragment | MarketProductListFragment

const Container = styled.div`
  ${MiniProductCardContainer}

  .flex-form {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`

const ImageContainer = styled.div`
  ${MiniProductCardImageContainer}
`

const LinkContainer = styled.a<{ $isAisle?: boolean }>`
  cursor: pointer;
  text-decoration: none;
  min-height: 3em;
  .MealKitDish {
    ${Ellipsis(3)}
    line-height: normal;
  }
  .Wine {
    ${Ellipsis(3)}
  }
  .MarketProduct {
    ${Ellipsis(3)}
  }
  .FrozenMealDish {
    ${Ellipsis(2)}
  }
`
const DescriptionContainer = styled.div`
  min-height: 2em;
`
const ContentContainer = styled.div`
  ${MiniProductCardContentContainer}
  .serving-size {
    display: flex;
    flex: 0;
    margin: 0;
  }
`
const MiniCardAddButtonContainer = styled.div`
   display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .mini-add-cart-button {
    width: 70%;

    .button-title {
     ${ResponsivePXValue('font-size', '6px')}
    } 
  }

  .flex-1 {
    flex: 1;
  }

  .wine {
    width: 25%;
  }
`

const TitleContainer = styled.div<{ $productType: 'FrozenMealDish' | 'MealKitDish' | 'MarketProduct' | 'Wine' | 'BabyBoxTub' | 'FrozenMeal' }>`
  height: 4em;
  
  ${ResponsivePXValue('padding-bottom', '8px')}

  ${(props): CSS => {
    if (props?.$productType === 'MealKitDish') {
      return `
        height: 3em;
        ${Ellipsis(2)}
      `
    }
  }}
`
const TimeContainer = styled.div`
  display: flex;
`
const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  ${ResponsivePXValue('width', '14px')}
  ${ResponsivePXValue('height', '14px')}
`

export interface MiniProductCardProps {
  meal: CombinedProduct
  className?: string
  loading?: boolean
  isAisle?: boolean
  isMixed?: boolean
}
enum DisplayTypeEnum {
  STANDARD = 'STANDARD',
  PARING = 'PARING',
}

enum MealkitModalEnum {
  NONE = 'NONE',
  SERVING_SIZE = 'SERVING_SIZE',
  RECIPE_CARD = 'RECIPE_CARD',
  ADD_TO_CART = 'ADD_TO_CART',
  COOK_WITHIN = 'COOK_WITHIN',
}

enum MarketProductModalEnum {
  SIGNUP = 'SIGNUP',
  UN_PAUSE = 'UN_PAUSE',
  MARKET = 'MARKET',
  NONE = 'NONE',
}
interface MiniProductCardState {
  addToCartInterrupted: boolean
  displayingPriceRange: boolean
  price: number | string
  loading: boolean
  displayType: DisplayTypeEnum
  activeMealKitModal: MealkitModalEnum
  activeMarketModal: MarketProductModalEnum
  displayReactivationModal: boolean
  chosenPairing: number
  wineQuantities: number[]
  servingSize: FrozenPortionSizeEnum
  currentCartValues: { [k: string]: number }
  currentAmountInCart: number
  mealKitAmount: number
  portionSize: NumberOfPortionsEnum
}

const DEFAULT_STATE: MiniProductCardState = {
  addToCartInterrupted: false,
  displayingPriceRange: false,
  price: 0,
  loading: false,
  displayType: DisplayTypeEnum.STANDARD,
  activeMealKitModal: MealkitModalEnum.NONE,
  activeMarketModal: MarketProductModalEnum.NONE,
  displayReactivationModal: false,
  chosenPairing: 0,
  wineQuantities: [],
  servingSize: FrozenPortionSizeEnum.SERVES_ONE,
  currentCartValues: {},
  currentAmountInCart: null,
  mealKitAmount: null,
  portionSize: NumberOfPortionsEnum.SERVES_2,
}

// TODO: @Anand use quantityInCart property on products and product groups

export function MiniProductCard({ meal, className, loading = false, isAisle, isMixed }: MiniProductCardProps): JSX.Element {

  const config = useConfig()
  const [form] = useForm()
  const [state, setState] = useState<MiniProductCardState>({ ...DEFAULT_STATE })
  const { data: userDetailsData } = useUserDetailsQuery({ ssr: config.fetchSSRQuery() })
  const [addDishToBox] = useAddDishToUserMenuMutation()
  const [removeMealKitFromCart] = useRemoveDishFromUserMenuMutation()
  const [addItemToCart] = useAddItemToCartMutation()
  const { addToast } = useToasts()
  const navigate = useNavigate()
  const events = useEvents()
  const theme = useTheme()
  const productType = meal?.__typename
  const { data: userCartData } = useUserCartQuery({ ssr: config.fetchSSRQuery() })
  const [changeCartItemQuantity] = useChangeCartItemQuantityMutation()
  const isOutOfStockItem = useOutOfStockNotifier()
  const checkoutStatus = userCartData?.currentUser?.checkoutStatus
  const hasActiveSubscription = !!checkoutStatus?.hasActiveSubscription
  const cartItems = userCartData?.currentUser?.activeCart?.cartItems
  const isDisplayingPairing = state.displayType === DisplayTypeEnum.PARING
  const [removeItemFromCart] = useRemoveItemFromCartMutation()
  const { data: appData = { app: { ...APP_DEFAULT_STATE } } } = useGetAppQuery()

  const mealKitCategory = productType === 'MealKitDish' ? meal?.mealKitCategories[0] : null
  const hasLunchCategory = !mealKitCategory?.canSubscribe || false
  const servingSizeNumber = convertEnumToNum(state.portionSize)

  const selectOptions: SelectOption[] = [
    {
      title: 'Serves 1',
      value: NumberOfPortionsEnum.SERVES_1,
    },
    {
      title: 'Serves 2',
      value: NumberOfPortionsEnum.SERVES_2,
    },
    {
      title: 'Serves 3',
      value: NumberOfPortionsEnum.SERVES_3,
    },
    {
      title: 'Serves 4',
      value: NumberOfPortionsEnum.SERVES_4,
    },

  ]
  const sessionSlugs = sessionStorage.getItem('SlugSessionString')
  const slugSessionObject :{ [k: string]: NumberOfPortionsEnum } = JSON.parse(sessionSlugs) || {}
  const sluggedPortion = slugSessionObject[meal?.slug]

  let defaultPortions:NumberOfPortionsEnum
  if (sluggedPortion) {
    defaultPortions = sluggedPortion
  } else if (appData.app.defaultPortions) {
    defaultPortions = appData.app.defaultPortions
  } else {
    defaultPortions = NumberOfPortionsEnum.SERVES_2
  }
  const isAvailable = (): boolean => {
    let available = true

    if (productType === 'FrozenMealDish') {
      for (let i = 0; i < meal?.products?.length; i++) {
        if (meal.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY ||
           meal.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE ||
            meal.products[i].availability === ProductAvailabilityEnum.NOT_AVAILABLE) {
          available = false
          break
        }
      }
    } else if (productType === 'MarketProduct') {
      if (!hasActiveSubscription || meal?.availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY ||
        meal.availability === ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE ||
        meal.availability === ProductAvailabilityEnum.NOT_AVAILABLE ||
        meal.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK) {
        available = false
      }
    } else if (productType === 'Wine') {
      if (meal.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK || meal.stockCount === 0) {
        available = false
      }
    } else if (productType === 'MealKitDish') {
      for (let i = 0; i < meal?.products?.length; i++) {
        if (meal.products[i].availability === ProductAvailabilityEnum.UNAVAILABLE_GEOGRAPHICALLY) {
          available = false
          break
        }
      }
    }
    return available
  }
  const getAllSizesAmount = (): number => {
    const cartObject = state.currentCartValues
    let sum = 0
    for (const key in cartObject) {
      if (Object.prototype.hasOwnProperty.call(cartObject, key)) {
        sum += cartObject[key]
      }
    }
    return sum
  }

  const getSpecificAmount = (): number => {

    if (productType === 'FrozenMealDish') {
      const cartObject = state.currentCartValues
      const servingSize = form.getFieldValue('servingSizeOptionsRadio')
      const mealId = meal?.products?.find(product => product.id === servingSize)?.id
      const number = cartObject[mealId]
      return number

    }

  }
  let categoryTitle: string
  if (productType === 'FrozenMealDish') {
    categoryTitle = meal?.frozenCategories[0]?.title
  }

  let mealTitle = meal.name
  let buttonText
  let mealSubtitle: string
  let subtitleColor: string
  // let upSellText: string
  let pillTitle: string
  let pillColor: string
  let pdpLink: string
  let hasSelectedPlan: boolean
  // let checkoutStatus: UserCheckoutStatusFragment
  let productId = meal.id
  let amount = state.currentCartValues[productId] || 0
  let specificAmount = state.currentCartValues[productId] || 0
  let dishTime: string
  let alwaysAvailableMarketProduct = false
  let isWeekendBox = false

  if (isAisle && mealTitle.length > 37) {
    const extraLetters = 33 - mealTitle.length
    mealTitle = mealTitle.slice(0, extraLetters) + '...'
  }

  const upSellText = meal?.upSellText

  if (productType === 'FrozenMealDish') {
    mealSubtitle = null
    subtitleColor = 'black'

    pillTitle = meal?.frozenCategories[0]?.title
    pdpLink = `/frozen/craft-meal/${meal?.slug}`
    amount = getAllSizesAmount()
    specificAmount = getSpecificAmount() || 0
    if (pillTitle.trim() === 'Pizza') {
      pillTitle = null
      pdpLink = `/frozen/craft-pizza/${meal?.slug}`

    } else if (pillTitle.trim() === 'Craft Kids') {
      pillTitle = null
      pdpLink = `/frozen/craft-kids/${meal?.slug}`

    } else if (pillTitle.trim() === 'Dessert') {
      pillTitle = null
      pdpLink = `/frozen/craft-dessert/${meal?.slug}`
    }
    pillColor = getCategoryBackground(meal?.frozenCategories[0]?.background)

    const product = meal?.products?.find((product) => { return product.frozenPortionSize === state.servingSize })
    productId = product?.id
  } else if (productType === 'MarketProduct') {

    alwaysAvailableMarketProduct = meal?.warehouses?.some((warehouse) => warehouse.orderType === ProductOrderTypeEnum.BOTH || warehouse.orderType === ProductOrderTypeEnum.ON_DEMAND)
    isWeekendBox = meal.isWeekendBox

    mealSubtitle = meal?.brand?.name
    subtitleColor = 'grey'
    pdpLink = `/market/${meal?.slug}`

  } else if (productType === 'Wine') {
    mealSubtitle = meal?.vineyard?.name
    subtitleColor = 'grey'
    pdpLink = `/wine/${meal?.slug}`

  } else if (productType === 'MealKitDish') {
    pdpLink = `/meal-kit/${meal?.slug}`
    hasSelectedPlan = checkoutStatus?.hasSelectedPlan
    // checkoutStatus = userCartData?.currentUser?.checkoutStatus
    mealSubtitle = 'EAT WITHIN ' + meal?.cookWithin + ' DAYS'
    subtitleColor = theme.colors.slates.bitter
    pillTitle = hasLunchCategory ? 'Lunch' : meal?.mealKitCategories[0]?.title
    pillColor = getCategoryBackground(meal?.mealKitCategories[0]?.background)

    const registeredUser = userDetailsData?.currentUser as UserDetailsFragment & RegisteredUserDetailsFragment
    const subscription: UserSubscriptionFragment = registeredUser?.subscriptions?.find((subscription) => subscription.id === userCartData?.currentUser?.activeMenu?.subscription?.id)
    const product = meal?.products?.find(product => { return product.portionSize === (subscription?.numberOfPortions) as unknown as PortionSizeEnum })
    dishTime = product ? `${product?.overallTime} mins` : `${meal?.overallTime?.min} - ${meal?.overallTime?.max} mins`

  }

  const _handleToggleReactivateSubscriptionModal = (display: boolean): void => {
    const activeModal = display ? MarketProductModalEnum.UN_PAUSE : MarketProductModalEnum.NONE
    setState((prevState) => update(prevState, { activeMarketModal: { $set: activeModal } }))
  }

  const _handleReactivateSubscription = () => {

    _handleToggleReactivateSubscriptionModal(false)
    _handleOnAdd()
    CartPlugin.shared().open()
  }
  const _showDefault = () => {
    setState((prevState) => update(prevState, {
      displayType: { $set: DisplayTypeEnum.STANDARD },
    }))
  }

  const _setLoading = (loading: boolean) => {
    setState((prevState) => update(prevState, {
      loading: { $set: loading },
    }))
  }

  const _handleServingSizeSelected = () => {
    setState((prevState) => update(prevState, {
      activeMealKitModal: { $set: MealkitModalEnum.NONE },
    }))
    _handleAddDishToBox()
  }

  const _switchReactivateSubscriptionModal = (display: boolean): void => {
    setState((prevState) => update(prevState, {
      displayReactivationModal: { $set: display },
    }))
  }
  const _onReactivateSubscription = () => {
    _handleAddDishToBox()
    CartPlugin.shared().open()
    _switchReactivateSubscriptionModal(false)
  }
  const _handleAddDishToBox = async (): Promise<void> => {
    _setLoading(true)

    const portionSize = state.portionSize

    try {
      await addDishToBox({
        variables: {
          input: {
            dishId: meal?.id,
            portionSize,
          },
        },
      })
      if (productType === 'MealKitDish') {
        const logData = {
          itemName: meal?.name,
          itemId: meal?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.id,
          itemGroupId: meal?.id,
          price: meal?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.price,
          itemBrand: 'UCOOK',
          itemCategory: meal?.mealKitCategories?.map((cat) => cat.id)?.join(', '),
          itemVariant: meal?.mealKitCategories?.map((cat) => cat.title)?.join(', '),
          itemListName: 'Meal Kit',
          quantity: 1,
          itemImage: meal?.coverImage?.location,
          itemStockCount: 1,
          itemChef: meal?.chef?.name,
          isMealkit: 'yes',
          itemServingSize: servingSizeNumber,
        }
        const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew

        events.hasAddedToCart(snakedData)

        addToast('Item successfully added to your order. Hit \'save\' in your cart before you go, or this dish won’t be saved.', {
          appearance: 'warning',
          autoDismiss: true,
        })
      }
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _setLoading(false)
  }

  const _handleShowAddMealKit = (): void => {
    if (checkoutStatus?.hasSelectedPortionSize) {
      _handleAddDishToBox()
    } else {
      setState((prevState) => update(prevState, {
        activeMealKitModal: { $set: MealkitModalEnum.SERVING_SIZE },
      }))
    }
  }

  const _handleAddProductToCart = async (): Promise<void> => {

    if (isOutOfStockItem(meal as unknown as Product, meal.quantityInCart, checkoutStatus.subscriptionIsSaved)) {
      return
    }

    setState((prevState) => update(prevState, { loading: { $set: true } }))

    const servingSize = form.getFieldValue('servingSizeOptionsRadio')

    let price
    let itemId
    if (productType === 'FrozenMealDish') {
      itemId = meal?.products?.find(product => product.id === servingSize)?.id
      price = meal?.products?.find(product => product.id === servingSize)?.price
    } else if (productType === 'MealKitDish') {
      itemId = meal?.products?.find(mealKit => mealKit.id === servingSize)?.id
      price = meal?.products?.find(mealKit => mealKit.id === servingSize)?.price
    } else {
      itemId = meal?.id,
      price = meal?.price
    }

    try {
      await addItemToCart({
        variables: { productId, quantity: 1 },
      })

      const logData = {
        itemName: meal?.name,
        itemId,
        itemGroupId: meal?.id,
        price,
        itemBrand: 'UCOOK',
        itemCategory: productType,
        itemVariant: 'MobileCard',
        itemListName: productType,
        quantity: 1,
        itemImage: meal?.coverImage?.location,
        itemStockCount: 1,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }

      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasAddedToCart(snakedData)

      addToast(`Successfully added (1) ${meal?.name} to cart.`, {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => update(prevState, { loading: { $set: false } }))
  }
  const _handleOnAdd = async (): Promise<void> => {

    const navigateToPlans = () => {
      if (config.isBrowser() && productType === 'MealKitDish') {
        window.sessionStorage.setItem('mealKitCategoryId', meal.mealKitCategories[0].id)
      }
      navigate('/meal-kit/plans')
    }

    const handleNoDefaultAddress = () => {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.ADD_LOCATION)
    }

    const handleDeliveryUnavailable = () => {
      ModalsPlugin.shared().toggleGlobalModal(true, GlobalModalTypeEnum.DELIVERY_UNAVAILABLE)
    }

    const updateCartInterruptedState = (condition: boolean) => {
      setState(prevState =>
        update(prevState, { addToCartInterrupted: { $set: condition } }),
      )
    }

    const handleMealKitFlow = () => {
      const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

      updateCartInterruptedState(noDefaultAddress || !isAvailable())

      if (noDefaultAddress) {
        handleNoDefaultAddress()
      } else if (!isAvailable()) {
        handleDeliveryUnavailable()
      } else {
        _handleShowAddMealKit()
      }
    }

    const handleOtherProductFlow = () => {
      const noDefaultAddress = !userDetailsData?.currentUser?.hasDefaultAddress

      updateCartInterruptedState(noDefaultAddress || !isAvailable())

      if (noDefaultAddress) {
        handleNoDefaultAddress()
      } else if (alwaysAvailableMarketProduct && isAvailable()) {
        _handleAddProductToCart()
      } else if (productType === 'MarketProduct' && userCartData?.currentUser?.isOnDemand) {
        const { hasPausedSubscription } = checkoutStatus || {}

        if (hasPausedSubscription && !alwaysAvailableMarketProduct) {
          _handleToggleReactivateSubscriptionModal(true)
        } else if (!hasActiveSubscription && !alwaysAvailableMarketProduct) {
          navigateToPlans()
        } else if (isAvailable() || alwaysAvailableMarketProduct) {
          _handleAddProductToCart()
        } else {
          handleDeliveryUnavailable()
        }
      } else if (isAvailable() || alwaysAvailableMarketProduct) {
        _handleAddProductToCart()
      } else {
        handleDeliveryUnavailable()
      }
    }

    if (productType === 'MealKitDish') {
      if (!hasSelectedPlan) {
        navigateToPlans()
      } else if (checkoutStatus?.hasPausedSubscription) {
        _switchReactivateSubscriptionModal(true)
      } else {
        handleMealKitFlow()
      }
    } else {
      handleOtherProductFlow()
    }
  }

  const isCardItemInBasket = () => {

    if (productType === 'FrozenMealDish') {
      const product = meal?.products?.find((product) => { return product.frozenPortionSize === state?.servingSize })
      const displayedProductId = product?.id

      if (Object.keys(state.currentCartValues).includes(displayedProductId)) {
        setState((prevState) => update(prevState, {
          currentAmountInCart: { $set: state.currentCartValues[displayedProductId] },
        }))
      }
    } else {

      const displayedProductId = meal?.id
      if (Object.keys(state.currentCartValues).includes(displayedProductId)) {
        setState((prevState) => update(prevState, {
          currentAmountInCart: { $set: state.currentCartValues[displayedProductId] },
        }))
      }
    }

  }

  const setCartAmountsObject = () => {
    if (productType === 'FrozenMealDish') {

      const cartObject = state.currentCartValues
      for (const key in cartObject) {
        const isInCart = cartItems.find(item => item.product.id === key)
        if (!isInCart) {
          delete cartObject[key]
        }
      }

      for (let i = 0; i < meal?.products.length; i++) {

        const currentServingSizeId = meal?.products[i].id

        for (let j = 0; j < cartItems?.length; j++) {
          const productId = cartItems[j]?.product?.id
          const quantity = cartItems[j]?.quantity

          if (currentServingSizeId === productId) {
            const currentCartValues = {
              [productId]: quantity,
            }

            setState((prevState) => update(prevState, {
              currentCartValues: { $merge: currentCartValues },
            }))
          }
        }
      }
    } else if (productType === 'MarketProduct' || productType === 'Wine') {
      const currentServingSizeId = meal?.id

      for (let j = 0; j < cartItems?.length; j++) {
        const productId = cartItems[j]?.product?.id
        const quantity = cartItems[j]?.quantity

        if (currentServingSizeId === productId) {
          const currentCartValues = {
            [productId]: quantity,
          }

          setState((prevState) => update(prevState, {
            currentCartValues: { $merge: currentCartValues },
          }))
        }
      }
    } else {
      const numberOfDishInCart = meal?.quantityInCart
      setState((prevState) => update(prevState, {
        mealKitAmount: { $set: numberOfDishInCart },
      }))
    }

  }

  const getMealPriceRange = (meal: FrozenMealDishListFragment): string => {

    const getPortionPrice = (portionSize: FrozenPortionSizeEnum): number => {
      return meal?.products?.find(product => product.frozenPortionSize === portionSize)?.price || 0
    }

    const lower = getPortionPrice(FrozenPortionSizeEnum.SERVES_ONE)
    const upper = getPortionPrice(FrozenPortionSizeEnum.SERVES_FOUR)
    return `${lower} - R${upper}`
  }

  const getServingSizeTitle = (product: FrozenMealListFragment): string => ServingSizeEnum[product.frozenPortionSize].toLowerCase() as string

  const getServingSizeOptions = () => {
    if (productType === 'FrozenMealDish') {

      const options = meal?.products?.map((product) => ({
        title: getServingSizeTitle(product),
        disabled: product.stockStatus === ProductStockStatusEnum.OUT_OF_STOCK,
        value: product.id,
      })) || []
      return options.sort((a, b) => a.title > b.title ? 1 : -1)
    }

  }

  const getAvailableServing = (): string => {
    const servingSizeOptions = getServingSizeOptions()
    const availableProduct = servingSizeOptions?.find(option => !option.disabled)
    return availableProduct?.value
  }

  const servingSizeOptions = () => {
    const options = getServingSizeOptions()
    if (options) {
      return options.map(option => {
        const number = Number(option.title.slice(-1))
        let amount = 0
        if (state.currentCartValues[option.value] > 0) {
          amount = state.currentCartValues[option.value]
        }
        return {
          className: 'size-option',
          value: option.value,
          disabled: option.disabled,
          element: <Paragraph bold variant='p2'>{number}</Paragraph>,
          count: amount,
        }
      })
    }

  }
  const _showPairing = () => {

    setState((prevState) => update(prevState, {
      displayType: { $set: DisplayTypeEnum.PARING },
    }))
  }

  let servingSizeOptionsRadioProps: RadioButtonProps = {}
  if (productType === 'FrozenMealDish') {
    servingSizeOptionsRadioProps = {
      className: 'mini-serving-size',
      isMiniCard: true,
      outline: false,
      name: 'servingSizeOptionsRadio',
      options: [...servingSizeOptions()],
      error: '',
      rules: [{ required: true, message: 'Please select a reason' }],
    }
  }
  const _setPrice = (value: string) => {

    if (productType === 'FrozenMealDish') {
      const selectedMeal = meal?.products?.find(product => product.id === value)
      let price: number | string = selectedMeal?.price || 0
      setState((prevState) => update(prevState, { servingSize: { $set: selectedMeal?.frozenPortionSize } }))
      const shouldDisplayPriceRange = price === 0 || !isAvailable() || selectedMeal?.stockCount === 0

      if (shouldDisplayPriceRange && (meal?.frozenCategories[0]?.title === 'Nourish' || meal?.frozenCategories[0]?.title === 'Artisan' || meal?.frozenCategories[0]?.title === 'Classics' || meal?.frozenCategories[0]?.title === 'Sides')) {
        price = getMealPriceRange(meal)
      } else if (!(meal?.frozenCategories[0]?.title === 'Nourish' || meal?.frozenCategories[0]?.title === 'Artisan' || meal?.frozenCategories[0]?.title === 'Classics' || meal?.frozenCategories[0]?.title === 'Sides')) {
        price = meal?.products?.find(product => product.frozenPortionSize === FrozenPortionSizeEnum.SERVES_ONE)?.price
      }

      setState((prevState) => update(prevState, {
        price: { $set: price },
        displayingPriceRange: { $set: shouldDisplayPriceRange },
      }))

    } else if (productType === 'Wine' || productType === 'MarketProduct') {
      const price = meal?.price
      setState((prevState) => update(prevState, {
        price: { $set: price },
      }))

    }

  }

  const _handleChange = (changedFields: FieldData[]) => {
    changedFields.forEach((field) => {
      (field.name as string[]).forEach((name) => {
        if (name === 'servingSizeOptionsRadio') {
          _setPrice(field.value)
        }
        if (name === 'numberOfPortions') {
          setState((prevState) => update(prevState, {
            portionSize: { $set: field.value },
          }))
        }

        if (meal.__typename === 'MealKitDish') {
          const slug = meal?.slug
          slugSessionObject[slug] = field.value
          const slugSessionObjectString = JSON.stringify(slugSessionObject)
          sessionStorage.setItem('SlugSessionString', slugSessionObjectString)
        }

      })
    })
  }

  const getDishId = async (id: string, cartDishesArray: UserMenuFragment): Promise<string> => {
    for (let i = 0; i < cartDishesArray?.dishes?.length; i++) {
      if (cartDishesArray?.dishes[i].dish?.id === id) {
        return cartDishesArray?.dishes[i].id
      }
    }
  }
  const _handleMealKitRemove = async (): Promise<void> => {

    _setLoading(true)
    const product = meal

    const cartData = userCartData?.currentUser?.activeMenu

    const cartDishId = await getDishId(meal.id, cartData)

    try {

      if (state.mealKitAmount > 0) {
        await removeMealKitFromCart({
          variables: {
            userMenuDishId: cartDishId,
          },
        })
      }

      if (productType === 'MealKitDish') {
        const portionSize = state.portionSize

        const logData = {
          itemName: meal?.name,
          itemId: meal?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.id,
          price: meal?.products.find(product => product.portionSize as unknown as NumberOfPortionsEnum === portionSize)?.price,
          itemBrand: 'UCOOK',
          itemCategory: meal?.mealKitCategories?.map((cat) => cat.id)?.join(', '),
          itemVariant: meal?.mealKitCategories?.map((cat) => cat.title)?.join(', '),
          itemListName: 'Meal Kit',
          quantity: 1,
          itemImage: meal?.coverImage?.location,
          itemStockCount: 1,
          itemChef: meal?.chef?.name,
          isMealkit: 'yes',
          itemServingSize: servingSizeNumber,
        }
        const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
        events.hasRemovedFromCart(snakedData)
      }

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    _setLoading(false)

  }

  const _handleOnRemove = async (): Promise<void> => {

    const product = meal

    try {
      setState((prevState) => update(prevState, { loading: { $set: true } }))
      if (state.currentCartValues[productId] === 1) {

        await removeItemFromCart({
          variables: {
            productId,
          },
        })
        const currentValuesObject = state.currentCartValues
        if (currentValuesObject[productId]) {
          delete currentValuesObject[productId]
        }
        setState((prevState) => update(prevState, { currentCartValues: { $set: currentValuesObject } }))

      } else {
        await changeCartItemQuantity({
          variables: {
            productId,
            quantity: state.currentCartValues[productId] - 1,
          },
        })
      }
      const logData = {
        itemName: product?.name,
        itemId: productId,
        price: Number(state.price),
        itemBrand: 'UCOOK',
        itemCategory: productType,
        itemVariant: productType,
        itemListName: productType,
        quantity: 1,
        itemImage: product?.coverImage?.location,
        itemStockCount: 1,
        itemChef: 'UCOOK',
        isMealkit: 'no',
        itemServingSize: 1,
      }
      const snakedData = Utilities.toSnakeCase(logData) as unknown as ItemInterfaceNew
      events.hasRemovedFromCart(snakedData)

      addToast('Item successfully removed from cart', {
        appearance: 'success',
        autoDismiss: true,
      })
    } catch (e) {
      addToast(e.message, {
        appearance: 'error',
        autoDismiss: true,
      })
    }
    setState((prevState) => update(prevState, { loading: { $set: false } }))

  }
  const _setMealServingSize = (value: string): void => {
    if (config.isBrowser()) {
      window.sessionStorage.setItem('mealServingSize', value)
    }
  }
  const _handleOnCenterClick = async (): Promise<void> => {
    if (state.mealKitAmount < 1 && productType === 'MealKitDish') {
      _handleOnAdd()
    } else if (specificAmount < 1 && productType !== 'MealKitDish') {
      _handleOnAdd()
    }
  }

  const _handlePdpNavigation = (e: React.MouseEvent<HTMLAnchorElement>) => {

    e.preventDefault()

    if (productType === 'FrozenMealDish' && (meal?.frozenCategories[0]?.title === 'Nourish' || meal?.frozenCategories[0]?.title === 'Artisan' || meal?.frozenCategories[0]?.title === 'Classics' || meal?.frozenCategories[0]?.title === 'Sides')) {
      _setMealServingSize(form.getFieldValue('servingSizeOptionsRadio'))
    }
    navigate(pdpLink)

  }

  useEffect(() => {
    if (state.addToCartInterrupted) {
      _handleOnAdd()
    }
  }, [userDetailsData?.currentUser?.addresses, isAvailable()])

  useEffect(() => {
    const availableServing = getAvailableServing()
    form.setFieldsValue({
      servingSizeOptionsRadio: availableServing,
    })
    _setPrice(availableServing)
  }, [])

  useEffect(() => {
    setCartAmountsObject()
    isCardItemInBasket()
  }, [userCartData, state.servingSize])

  useEffect(() => {

    if (productType === 'Wine' || productType === 'MarketProduct') {
      const price = meal?.price
      setState((prevState) => update(prevState, {
        price: { $set: price },
      }))
    }
  }, [meal])

  useEffect(() => {
    setState((prevState) => update(prevState, {
      portionSize: { $set: defaultPortions },
    }))
  }, [userDetailsData])

  if (state.displayingPriceRange) {
    buttonText = 'OUT OF STOCK'
  } else {
    const isAvailableForOrder = productType !== 'MarketProduct' || !userCartData?.currentUser?.isOnDemand ||
    alwaysAvailableMarketProduct || (meal.availability !== ProductAvailabilityEnum.UNAVAILABLE_FOR_ORDER_TYPE && hasActiveSubscription)

    buttonText = isAvailableForOrder
      ? (productType === 'MarketProduct' ? 'ADD TO CART' : isAvailable() ? 'ADD TO CART' : 'OUT OF STOCK')
      : 'GET STARTED'
  }

  let stockProduct = null

  switch (productType) {
    case 'FrozenMealDish':
      stockProduct = meal?.products?.find((product) => { return product.frozenPortionSize === state?.servingSize })
      break
    case 'Wine':
      stockProduct = meal
      break
    case 'MarketProduct':
      stockProduct = meal
      break
    default:
      break
  }

  let upSellProducts: (UpSellFrozenMealDishFragment | UpSellMarketProductFragment | UpSellWineFragment)[] = []

  if (productType === 'MealKitDish') {
    upSellProducts = getUpSellProducts(meal)
  }

  const displayUpSellProducts = isDisplayingPairing && upSellProducts.length > 0
  const numberDefaultPortions = convertEnumToNum(defaultPortions)

  return (
    <Container className={className}>
      <If condition={productType === 'MealKitDish'}>
        <HashAnchor selectorId={meal.slug} />
        <HashAnchor selectorId={Utilities.convertToKebabCase(pillTitle)} />
      </If>
      <MealkitModal
        activeModal={state.activeMealKitModal}
        onSubmit={_handleServingSizeSelected}
        onClose={() => { setState({ ...state, activeMealKitModal: MealkitModalEnum.NONE }) }} />
      <ReactivateSubscriptionModal
        open={state.displayReactivationModal}
        onReactivation={_onReactivateSubscription}
        onClose={() => { _switchReactivateSubscriptionModal(false) }} />
      <If condition={upSellText?.length > 0}>
        <FloatingUpSell text={upSellText} isMiniCard={true} />
      </If>
      <If condition={isWeekendBox}>
        <Choose>
          <When condition={upSellText?.length > 0}>
            <FloatingUpSell text={upSellText} />
          </When>
          <Otherwise>
            <FloatingUpSell text={'Delivered ' + _handleWeekendBoxDeliveryText()} />
          </Otherwise>
        </Choose>
      </If>
      <Form className='flex-form' form={form} onFieldsChange={_handleChange}>
        <LinkContainer onClick={_handlePdpNavigation}>
          <ImageContainer>
            <If condition={productType === 'MealKitDish' && meal?.discoveryHealthyDiningItem}>
              <DiscoveryVitality />
            </If>
            <ResponsiveImage image={meal.coverImage} lazy={false} />
          </ImageContainer>
        </LinkContainer>
        <ContentContainer className='fluid-grow'>
          <If condition={(isAisle !== true) && !!pillTitle}>
            <Pill title={pillTitle} backgroundColor={pillColor} isMini />
            <Spacer universal='8px' />
          </If>
          <If condition={!isAisle && productType === 'MealKitDish'}>
            <SelectInput
              name='numberOfPortions'
              value={defaultPortions}
              readOnly={true}
              placeholder={`Serves ${numberDefaultPortions}`}
              itemsToDisplay={4}
              options={selectOptions}
              rules={[{ required: true }]}
              showLabel={false}
              className='no-margin'
            />
            <Spacer universal='8px' />
          </If>
          <TitleContainer $productType={productType}>
            <LinkContainer onClick={_handlePdpNavigation} $isAisle={isAisle}>
              <Heading className={productType} variant='h6'> {mealTitle} </Heading>
            </LinkContainer>
          </TitleContainer>
          <If condition={!isAisle && productType === 'MealKitDish'}>
            <TimeContainer>
              <IconContainer>
                <Icon icon={IconEnum.ALARM_OUTLINE} color={subtitleColor} />
              </IconContainer>
              <Spacer universal='4px' variant='horizontal' />
              <Paragraph variant='p3' color={subtitleColor}> {dishTime} </Paragraph>
            </TimeContainer>
          </If>
          <If condition={isAisle !== true && !!mealSubtitle}>
            <Spacer universal='4px' />
            <DescriptionContainer>
              <Paragraph variant='p3' color={subtitleColor}>{mealSubtitle}</Paragraph>
            </DescriptionContainer>
            <Spacer universal='8px' className='fluid-grow' />
          </If>
          <If condition={isMixed && isAisle && !(categoryTitle === 'Nourish' || categoryTitle === 'Artisan' || categoryTitle === 'Classics' || categoryTitle === 'Sides')}>
            <Spacer universal='50px' className='fluid-grow' />
          </If>
          <If condition={isMixed && !(categoryTitle === 'Nourish' || categoryTitle === 'Artisan' || categoryTitle === 'Classics' || categoryTitle === 'Sides') && !isAisle}>
            <Spacer universal='80px' className='fluid-grow' />
          </If>
          <If condition={state.price !== 0}>
            <Choose>
              <When condition={!!stockProduct}>
                <LowStockContainer product={stockProduct}>
                  <Paragraph variant='p5' bold> {`R${state.price}`} </Paragraph>
                </LowStockContainer>
              </When>
              <Otherwise>
                <Paragraph variant='p5' bold> {`R${state.price}`} </Paragraph>
              </Otherwise>
            </Choose>
          </If>
          <If condition={categoryTitle === 'Nourish' || categoryTitle === 'Artisan' || categoryTitle === 'Classics' || categoryTitle === 'Sides'}>
            <Rule color='slate' />
            <Paragraph variant='p3' color={subtitleColor}>Serving size:</Paragraph>
            <RadioButton {...servingSizeOptionsRadioProps} />
            <Spacer universal='12px' />
          </If>
          <Choose>
            <When condition={productType !== 'MealKitDish'}>
              <Spacer universal='8px' />
              <Button
                className='mini-add-button'
                title={buttonText}
                color='black'
                size='small'
                isMiniCardButton
                fullWidth
                loading={loading || state.loading}
                disabled={loading || state.displayingPriceRange}
                amount={amount}
                amountServingSizeSpecific={specificAmount}
                onClick={_handleOnCenterClick}
                onLeftIconClick={_handleOnRemove}
                onRightIconClick={_handleOnAdd} />
            </When>
            <Otherwise>
              <If condition={isAisle}>
                <Spacer universal='8px' />
              </If>
              <MiniCardAddButtonContainer>
                <Button
                  className='mini-add-cart-button flex-1'
                  isMiniCardButton
                  isMealKitMini
                  title='ADD TO  '
                  icon={IconEnum.CART}
                  fullWidth
                  size='small'
                  color='black'
                  loading={loading || state.loading}
                  disabled={loading || state.displayingPriceRange || !isAvailable()}
                  amount={state.mealKitAmount}
                  onClick={_handleOnCenterClick}
                  onLeftIconClick={_handleMealKitRemove}
                  onRightIconClick={_handleOnAdd} />
                <If condition={upSellProducts.length > 0}>
                  <Spacer variant='horizontal' universal='8px' />
                  <Button
                    className='wine'
                    color='tundora'
                    size='small'
                    isMiniCardButton
                    icon={IconEnum.ADD_CIRCLE_OUTLINE}
                    onClick={_showPairing} />
                </If>
              </MiniCardAddButtonContainer>
            </Otherwise>
          </Choose>
        </ContentContainer>
      </Form>
      <UpSellProductsModal
        open={displayUpSellProducts}
        upSellProducts={upSellProducts.slice(0, 3)}
        onClose={() => { _showDefault() }} />
      <ReactivateSubscriptionModal
        open={state.activeMarketModal === MarketProductModalEnum.UN_PAUSE}
        onReactivation={_handleReactivateSubscription}
        onClose={() => { _handleToggleReactivateSubscriptionModal(false) }} />
    </Container>
  )
}
